import { NgModule } from '@angular/core';
import { ValidationErrorsComponent } from './validation-errors.component';
import { CommonModule } from '@angular/common';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ValidationErrorsComponent,
  ],
  exports: [ValidationErrorsComponent],
  providers: [
  ]
})
export class ValidationModule { }
