import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
  selector: 'app-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss']
})

export class ValidationErrorsComponent implements OnInit {
  @Input('errors') errors;

  constructor() { }

  ngOnInit() {
    // console.log(this.errors);
  }

}
